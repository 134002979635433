* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    background-image: url('/public/images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    background-image: url('/public/images/img-1.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    background-image: url('/public/images/img-8.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .ph-text {
    color:hotpink;
    text-align: center;
    padding:20px;
  }

  .f-25 {
    font-size: 25px;
  }

  @media screen and (min-width:900px) {
    .f-25 {
      margin:0 20% !important;
    }
  }
  @media screen and (max-width:700px) {
    .thumb {
      width: 100% !important;
  height: auto !important;
    }
  }

  .center {
    text-align:center;
  }

  .wrap {
    overflow-wrap: word-break;
  }

  .pad-30 {
    padding:10px;
  }

  .thumb {
    width: 100% !important;
  height: auto !important;
  }

  .i-size {
    max-width: 300px !important;
  }